$(function() {
    $(".gallery-thumbs").fancybox({
        prevEffect: 'none',
        nextEffect: 'none',
        openEffect: 'elastic',
        openSpeed: 150,
        closeEffect: 'elastic',
        closeSpeed: 150,
        closeClick: true,
        helpers: {
            title: {
                type: 'outside'
            },
            thumbs: {
                width: 50,
                height: 50
            }
        }
    });

});